import React from "react";
import { Banner } from "../components/Banner/Banner";
import { ProductList } from "../components/ProductList/ProductList";

const Home = () => {
  return (
    <div>
      <Banner />
      <ProductList />
    </div>
  )
}

export default Home
