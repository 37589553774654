export const LOCALIZATION = {
    LOGIN: `Kirjaudu sisään`,
    USERNAME: `Käyttäjätunnus`,
    PLACE: `Aseta`,
    PASSWORD: `Salasana`,
    FNAME: `Etunimi`,
    LNAME: `Sukunimi`,
    FORGOT: `Unohtuiko`,
    REGISTER: `Rekisteröidy`,
    REGISTERED_ALREADY: `Oletko jo rekisteröitynyt?`,
    ERROR_LOGIN: `Väärä käyttäjätunnus tai salasana`,
    ERROR_USER_ADD: `Käyttäjätunnuksen luonti estetty, yritä toisia tunnuksia`,
    SUCCESS_USER_ADD: `Käyttäjä lisätty`,
    WELCOME: `Tervetuloa`,
    LOGOUT: `Kirjaudu ulos`,
    CATEGORY: `Kategoria`,
    ADD: `Lisää`,
    DESCRIPTION: `Kuvaus`,
    SUBMIT: `Lähetä`,
    PRODUCT: `Tuote`,
    IMAGE: `Kuva`,
    NAME: `Nimi`
}